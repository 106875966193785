






































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
// import { ElForm } from "element-ui/types/form";
import { getInfo , getLogout} from "@/serve/index";
import { SubmitTokensubmit, getTokenDetail } from "@/serve/baseSet/baseSet";
@Component({
  name: "navigation",
})
export default class Home extends Vue {
  @Prop({ type: Function }) getCollapse!: Function;
  @Prop({ type: Function }) clickMenu!: Function;
  @Prop({ type: Function }) getActiveIndex!: Function;
  public menuVal = "";
  public isCollapse = false;
  public showSearch = false;
  public labelPosition = "left";
  public userInfo = {};
  public checkSign = "基本设置";
  public dialogFormVisible = false;
  public isBaseSet = false;
  public checkappointIp = true;
  public checklineIp = true;
  public Url: any;
  public formData = {
    appointIp: "",
    lineIp: "",
  };
  /**验证url格式是否正确 */
  private checkOut(
    attr: "checklineIp" | "checkappointIp",
    val: "lineIp" | "appointIp"
  ) {
    const strRegex = /^(https:\/\/)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+(:\d+)*(\/\w+\.\w+)*([?&]\w+=\w*)*$/;
    this[attr] = strRegex.test(this.formData[val]);
    return this[attr];
  }
  public async created() {
    this.getInfo();
    this.getTokenDetail();
  }
  //获取权限相关信息
  public async getInfo() {
    const res = await getInfo();
    this.userInfo = res.data.sysUser;
    //判断是否为医院管理员
    if (res.data.roles[0] === 3) {
      this.isBaseSet = true;
      sessionStorage.setItem("isAdmin", JSON.stringify(this.isBaseSet));
    }
    //储存用户权限
    sessionStorage.setItem("permissions", JSON.stringify(res.data.permissions));
    //储存登录用户信息
    sessionStorage.setItem("userInfo", JSON.stringify(res.data.sysUser));
  }
  // 基本设置配置详情
  public async getTokenDetail() {
    const res = await getTokenDetail();
    if (res.code === 200) {
      //   console.log('res :>> ', res);
      this.formData.lineIp = res.data.lineIp;
      this.formData.appointIp = res.data.appointIp;
      this.Url = res.data;
      this.$forceUpdate();
    }
  }
  // 取消按钮
  public hanlderCancel() {
    this.dialogFormVisible = false;
    this.checkappointIp = true;
    this.checklineIp = true;
    const { appointIp, lineIp } = this.Url;
    this.formData.appointIp = appointIp;
    this.formData.lineIp = lineIp;
    // (this.$refs['formData'] as ElForm).resetFields();
  }
  // 确认按钮
  public async handlerSubmit(): Promise<void> {
    if (
      this.checkOut("checklineIp", "lineIp") &&
      this.checkOut("checkappointIp", "appointIp")
    ) {
      const res = await SubmitTokensubmit(this.formData);
      if (res.code === 200) {
        this.getTokenDetail();
        this.hanlderCancel();
      }
    }
  }
  //下载公钥
  public async handlerDownload() {
    const token = JSON.parse(sessionStorage.getItem("Token") as string)
      .access_token;
    window.open("manage/request/token/downPublicKeyText/" + token);
  }
  private handleSelect(row: any) {
    this.$emit("clickMenu", row);
    this.$emit("getActiveIndex", row.path);
    this.$router.push(row.path);
    this.showSearch = false;
  }
  public setCollapse() {
    this.isCollapse = !this.isCollapse;
    this.$emit("getCollapse", this.isCollapse);
  }
  //搜索菜单
  public querySearch(queryString: any, cb: (arg0: any) => void) {
    const menuData = JSON.parse(sessionStorage.getItem("menuData") as string);
    const results = queryString
      ? menuData.filter(this.createFilter(queryString))
      : menuData;
    // 调用 callback 返回建议列表的数据
    cb(results);
  }
  //清空缓存然后退出到登录,或者是用户点击个人设置
  public command(row: string) {
    if (row === "out") {
      getLogout().then((res) => {
        if (res.code === 200) {
          console.log("res :>> ", res);
          sessionStorage.clear();
          localStorage.clear();
          this.$router.push("/");
        } 
      });
    }
  }
  public createFilter(queryString: string) {
    return (restaurant: { value: string; menu: string }) => {
      return (
        restaurant.menu.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      );
    };
  }
}
